import App from 'next/app';
import 'normalize.css/normalize.css';

import '../styles/index.scss';

import '../styles/_variables.css';
import '../styles/_fonts.css';
import '../styles/_typography.css';

export default App;
